<template>
  <div
    :class="{
      [classToUse]: classToUse != null,
      SelectComponent: classToUse == null
    }"
  >
    <multiselect
      class="selectSearch"
      v-model="updateV"
      @input="handelChangeSelect()"
      v-bind="$props"
    >
      <span slot="noResult">Aucun {{ champName }} trouvé </span>
      <span slot="noOptions">La liste est vide</span>
    </multiselect>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      require: true
    },
    champName: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    change: {
      type: Function
    },
    filterName: {
      type: String
    },
    'max-height': {
      default: 250
    },
    showLabels: {},
    classToUse: {
      default: null
    }
  },
  data() {
    return {
      ValueUpdated: this.value
    };
  },
  methods: {
    handelChangeSelect() {
      this.change({ champName: this.filterName, value: this.ValueUpdated });
    }
  },
  computed: {
    updateV: {
      get() {
        return this.value;
      },
      set(updateV) {
        this.ValueUpdated = updateV;
      }
    }
  },
  components: { Multiselect: () => import('vue-multiselect') }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.SelectComponent {
  width: 100%;
  height: 50%;
  .multiselect__placeholder {
    margin: 0px;
  }
  .selectSearch {
    min-height: 28px;
  }
  .multiselect__option {
    line-height: 10px;
    padding: 9px;
    min-height: 15px;
    font-size: 10px;
  }
  .multiselect__option:after {
    line-height: 15px;
    font-size: 10px;
    padding-right: 24px;
    padding-left: 7px;
  }
  .multiselect__select {
    width: 23px;
    height: 15px;
    padding-top: 18px;
  }
  .multiselect__element {
    font-size: 10px;
  }
  .multiselect__tags {
    border: 1px solid #ced4da;
    font-size: 11px;
    min-height: 15px;
    padding: 3px 22px 3px 5px;
    width: 100%;
    margin: auto;
    .multiselect__single {
      font-size: 10px;
      padding-left: 0px;
      margin-bottom: 0px;
    }
    .multiselect__input {
      font-size: 10px;
      margin-bottom: 0px;
    }
  }
}
.multiselect--active {
  z-index: 50 !important;
}
</style>
